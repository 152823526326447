import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src281035820/src/kinetic-ui/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { TableProps } from '../../components/TableProps';
export const name = 'CategoryTable';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  name,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h2>{name}</h2>
    <TableProps columns={[{
      name: 'name',
      type: 'text'
    }, {
      name: 'slug',
      type: 'text'
    }, {
      name: 'createdAt',
      type: 'text'
    }, {
      name: 'createdBy',
      type: 'text'
    }, {
      name: 'updatedAt',
      type: 'text'
    }, {
      name: 'updatedBy',
      type: 'text'
    }]} tableOptions={[{
      name: 'kappSlug',
      type: 'string'
    }]} mdxType="TableProps" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      